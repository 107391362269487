'use client';

import { useEffect } from 'react';
const ErrorPage: AppErrorPage = ({
  error,
  reset
}) => {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);
  return <div data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
      <h2>Something went wrong!</h2>
      <button type="button" onClick={
    // Attempt to recover by trying to re-render the segment
    () => reset()}>
        Try again
      </button>
    </div>;
};
export default ErrorPage;